import { createRouter, createWebHistory } from 'vue-router'
import useLoginManager from '@/services/loginManager';
import { useSnackStore, type IShowSnack } from '@/stores/snack';
import { UserPrivilegeEnum } from '@/api/users';

const loginManager = useLoginManager();

declare module 'vue-router' {
  interface RouteMeta {
    title?: string
    // OR between the outer array, AND between the inner array
    // [
    //   ['Priv1', 'Priv2'],
    //   ['Priv3', 'Priv4'],
    // ],
    // means that the user must have either Priv1 AND Priv2, or Priv3 AND Priv4
    requiredPrivileges?: string[][]
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomeView.vue'),
      meta: {
        title: "Home",
      },
    },
    {
      path: '/login',
      name: 'login',
      components: {
        fullscreen: () => import('../views/LoginView.vue'),
      },
      meta: {
        title: "Login",
        fullscreen: true,
      },
    },
    {
      path: '/oidc/callback',
      name: 'oidc-callback',
      components: {
        fullscreen: () => import('../views/OidcCallbackView.vue'),
      },
      meta: {
        title: "OIDC Callback",
        fullscreen: true,
      },
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        title: "Logout",
      },
      redirect: "/login",
    },
    {
      path: "/machine/operators",
      name: "machine operators",
      meta: {
        title: "Machine operators",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_OPERATORS,],
        ],
      },
      component: () => import("../views/OperatorsView.vue"),
    },
    {
      path: "/machine/battery",
      name: "battery types",
      meta: {
        title: "Battery types",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_SYSTEM,],
        ],
      },
      component: () => import("../views/BatteryTypesView.vue"),
    },
    {
      path: "/machine/types",
      name: "machine types",
      meta: {
        title: "Machine types",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_SYSTEM,],
        ],
      },
      component: () => import("../views/MachineTypesView.vue"),
    },
    {
      path: "/teltonika-configuration",
      name: "teltonika configuration",
      meta: {
        title: "Teltonika configuration",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_SYSTEM,],
        ],
      },
      component: () => import("../views/TeltonikaConfigurationView.vue"),
    },
    {
      path: "/danfoss-configuration",
      name: "danfoss configuration",
      meta: {
        title: "Danfoss configuration",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_SYSTEM,],
        ],
      },
      component: () => import("../views/DanfossConfigurationView.vue"),
    },
    {
      path: "/mail-log",
      name: "mail log",
      meta: {
        title: "Mail log",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_SYSTEM,],
        ],
      },
      component: () => import("../views/MailLogView.vue"),
    },
    {
      path: "/machines",
      name: "machines",
      meta: {
        title: "Machines",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_MACHINES,],
        ],
      },
      component: () => import("../views/MachinesView.vue"),
    },
    {
      path: "/customers",
      name: "customers",
      meta: {
        title: "Customers",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_CUSTOMERS,],
        ],
      },
      component: () => import("../views/CustomersView.vue"),
    },
    {
      path: "/machines/:id/card/:tab?",
      name: "machine-card",
      props: true,
      meta: {
        title: "Machine card",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_MACHINES,],
        ],
      },
      component: () => import("../views/MachinesDetailView.vue"),
    },
    {
      path: "/customers/:id/card/:tab?",
      name: "customer-card",
      props: true,
      meta: {
        title: "Customer card",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_CUSTOMERS,],
        ],
      },
      component: () => import("../views/CustomersDetailView.vue"),
    },
    {
      path: "/users",
      name: "users",
      meta: {
        title: "Users",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_USERS,],
        ],
      },
      component: () => import("../views/UsersView.vue"),
    },
    {
      path: "/users/:id/card/:tab?",
      name: "user-card",
      props: true,
      meta: {
        title: "User card",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_USERS,],
        ],
      },
      component: () => import("../views/UsersDetailView.vue"),
    },
    {
      path: "/roles",
      name: "roles",
      meta: {
        title: "Roles",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_SYSTEM,],
        ],
      },
      component: () => import("../views/RolesView.vue"),
    },
    {
      path: "/crm/system",
      name: "crm-system",
      meta: {
        title: "CRM system",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_SYSTEM,],
        ],
      },
      component: () => import("../views/CRMSystemView.vue"),
    },
    {
      path: "/profiles",
      name: "profiles",
      meta: {
        title: "Profiles",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_SYSTEM,],
        ],
      },
      component: () => import("../views/ProfilesView.vue"),
    },
    {
      path: "/profiles",
      name: "profiles",
      meta: {
        title: "Profiles",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_SYSTEM,],
        ],
      },
      component: () => import("../views/ProfilesView.vue"),
    },
    {
      path: "/doctor/summary",
      name: "summary",
      meta: {
        title: "Summary",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_DOCTOR,],
        ],
      },
      component: () => import("../views/DoctorSummaryView.vue"),
    },
    {
      path: "/doctor/messages",
      name: "messages",
      meta: {
        title: "Messages",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_DOCTOR,],
        ],
      },
      component: () => import("../views/DoctorMessagesView.vue"),
    },
    {
      path: "/doctor/errors2",
      name: "errors2",
      meta: {
        title: "Errors",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_DOCTOR,],
        ],
      },
      component: () => import("../views/DoctorErrorsTeltonikaView.vue"),
    },
    {
      path: "/doctor/disclaimers",
      name: "disclaimers",
      meta: {
        title: "Disclaimers",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_DOCTOR,],
        ],
      },
      component: () => import("../views/DoctorDisclaimerView.vue"),
    },
    {
      path: "/doctor/errors",
      name: "errors",
      meta: {
        title: "Errors",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_DOCTOR,],
        ],
      },
      component: () => import("../views/DoctorErrorsView.vue"),
    },
    {
      path: "/doctor/overloads",
      name: "overloads",
      meta: {
        title: "Overloads",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_DOCTOR,],
        ],
      },
      component: () => import("../views/DoctorOverloadsView.vue"),
    },
    {
      path: "/servis/:tab?",
      name: "servis",
      props: true,
      meta: {
        title: "servis",
        requiredPrivileges: [
          [UserPrivilegeEnum.ALLOWED_SERVICE_PORTAL,],
        ],
      },
      component: () => import("../views/ServisView.vue"),
    },
    {
      path: "/servis/:id/ticket/:tab?",
      name: "servis-ticket",
      props: true,
      meta: {
        title: "Servis ticket",
        requiredPrivileges: [
          [UserPrivilegeEnum.ALLOWED_SERVICE_PORTAL,],
        ],
      },
      component: () => import("../views/ServisDetailView.vue"),
    },
    {
      path: "/crm/pool",
      name: "crm-pool",
      meta: {
        title: "CRM pool",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_CRM_POOL,],
        ],
      },
      component: () => import("../views/CRMPoolView.vue"),
    },
    {
      path: "/crm/billing",
      name: "crm-billing",
      meta: {
        title: "CRM billing",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_CRM_BILLING,],
        ],
      },
      component: () => import("../views/CRMBillView.vue"),
    },
    {
      path: "/crm/customers",
      name: "crm-customers",
      meta: {
        title: "My Customers",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_CRM_LEADS,],
        ],
      },
      component: () => import("../views/CRMCustomersView.vue"),
    },
    {
      path: "/crm/lead",
      name: "crm-lead",
      meta: {
        title: "CRM lead",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_CRM_LEADS,],
        ],
      },
      component: () => import("../views/CRMLeadView.vue"),
    },

    {
      path: "/crm/deal",
      name: "crm-deal",
      meta: {
        title: "CRM deal",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_CRM_DEALS,],
        ],
      },
      component: () => import("../views/CRMDealView.vue"),
    },
    {
      path: "/crm/spam",
      name: "crm-spam",
      meta: {
        title: "CRM spam",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_CRM_SPAM,],
        ],
      },
      component: () => import("../views/CRMSpamView.vue"),
    },
    {
      path: "/crm/all",
      name: "crm-all",
      meta: {
        title: "CRM all tickets",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_CRM,],
        ],
      },
      component: () => import("../views/CRMAllView.vue"),
    },
    {
      path: "/crm/exports",
      name: "crm-export",
      meta: {
        title: "CRM all tickets",
        requiredPrivileges: [
          [UserPrivilegeEnum.ATLAS_ALLOW_CRM_EXPORT,],
        ],
      },
      component: () => import("../views/CRMExportsView.vue"),
    },
    {
      path: '/:catchAll(.*)',
      name: 'not-found',
      components: {
        fullscreen: () => import("../views/NotFoundView.vue"),
      },
      meta: {
        title: "Not Found",
        fullscreen: true,
      }
    },
  ]
});

router.beforeResolve(async (to) => {
  // check if user can even work in atlas
  // if logged and has no permission, show error
  await loginManager.init(router);

  if (loginManager.isLogged.value &&
    !loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_LOGIN)
  ) {
    const snackStore = useSnackStore();
    snackStore.showSnack(<IShowSnack>{
      message: "You have no access to Atlas",
      timeout: 3000,
      color: "error",
    });

    // if not on login page
    if (!(['login', 'oidc-callback'].includes(to.name?.toString() || ''))) {
      // redirect to login page
      return { name: 'login' }
    }
  }


  // if user is not logged in, redirect to login page
  if (
    !loginManager.isLogged.value &&
    // Avoid an infinite redirect
    !(['login', 'oidc-callback'].includes(to.name?.toString() || ''))
  ) {
    return { name: 'login' }
  }
});

router.beforeResolve((to) => {
  // check if user has privileges required for the route
  const privs = to.meta.requiredPrivileges || [];
  if (privs.length > 0) {
    if (!privs.some((andPrivileges) => andPrivileges.every((privilege) => loginManager.hasPrivilege(privilege)))) {
      return { name: 'home' }
    }
  }
});

export default router
