import { HTTP } from "@/services/http";
import { type DRFOptionsResponse, type PaginatedType } from "@/utils/DjangoTypes";
import type { MachineType } from "./machines";
import type { CustomerType } from "./customers";
import { type ServerTableParamsType, getServerTableQuery } from "@/utils/TableUtils";

function randomPin() {
    return Math.floor(100000 + Math.random() * 900000).toString();
}

export enum UserPrivilegeEnum {
    DEVELOPER = "DEVELOPER",

    SUPERADMIN = "SUPERADMIN",
    OPERATOR = "OPERATOR",
    ATLAS_LOGIN = "ATLAS_LOGIN",
    ATLAS_ALLOW_CUSTOMERS = "ATLAS_ALLOW_CUSTOMERS",
    ATLAS_ALLOW_OPERATORS = "ATLAS_ALLOW_OPERATORS",
    ATLAS_ALLOW_MACHINES = "ATLAS_ALLOW_MACHINES",
    ATLAS_ALLOW_USERS = "ATLAS_ALLOW_USERS",
    ATLAS_ALLOW_DOCTOR = "ATLAS_ALLOW_DOCTOR",
    ATLAS_ALLOW_SYSTEM = "ATLAS_ALLOW_SYSTEM",
    ATLAS_ALLOW_CRM_EXPORT = "ATLAS_ALLOW_CRM_EXPORT",
    ATLAS_ALLOW_EDIT_USER = "ATLAS_ALLOW_EDIT_USER",
    ATLAS_ALLOW_EDIT_CUSTOMER = "ATLAS_ALLOW_EDIT_CUSTOMER",
    ATLAS_ALLOW_EDIT_OPERATOR = "ATLAS_ALLOW_EDIT_OPERATOR",
    ATLAS_ALLOW_EDIT_MACHINES = "ATLAS_ALLOW_EDIT_MACHINES",
    ATLAS_ALLOW_CRM = "ATLAS_ALLOW_CRM",
    ATLAS_ALLOW_CRM_POOL = "ATLAS_ALLOW_CRM_POOL",
    ATLAS_ALLOW_CRM_BILLING = "ATLAS_ALLOW_CRM_BILLING",
    ATLAS_ALLOW_CRM_LEADS = "ATLAS_ALLOW_CRM_LEADS",
    ATLAS_ALLOW_CRM_DEALS = "ATLAS_ALLOW_CRM_DEALS",
    ATLAS_ALLOW_CRM_SPAM = "ATLAS_ALLOW_CRM_SPAM",
    ATLAS_BDM = "ATLAS_BDM",
    ATLAS_BDM_APPROVER = "ATLAS_BDM_APPROVER",
    PORTAL_LOGIN = "PORTAL_LOGIN",
    MOBILE_APP_LOGIN = "MOBILE_APP_LOGIN",
    ALLOW_NOTICE_BOARD = "ALLOW_NOTICE_BOARD",
    ALLOWED_GARAGE = "ALLOWED_GARAGE",
    ALLOWED_DAKTELA = "ALLOWED_DAKTELA",
    ALLOWED_TELTONIKA = "ALLOWED_TELTONIKA",
    ALLOWED_DANFOSS = "ALLOWED_DANFOSS",
    ALLOWED_CREDENTIALS_CUSTOMER = "ALLOWED_CREDENTIALS_CUSTOMER",
    ALLOWED_FAQ = "ALLOWED_FAQ",
    ALLOWED_DOWNLOAD = "ALLOWED_DOWNLOAD",
    ALLOWED_NEWSLETTER = "ALLOWED_NEWSLETTER",
    ALLOWED_CALC = "ALLOWED_CALC",
    ALLOWED_SPARE_PARTS = "ALLOWED_SPARE_PARTS",
    ALLOWED_QUESTIONNAIRE = "ALLOWED_QUESTIONNAIRE",
    ALLOWED_DASHBOARD = "ALLOWED_DASHBOARD",
    ALLOWED_OFFER_CARD = "ALLOWED_OFFER_CARD",
    ALLOWED_JOB_CARD = "ALLOWED_JOB_CARD",
    APPROVE_QUESTIONNAIRE = "APPROVE_QUESTIONNAIRE",
    APPROVE_JOB_CARD_VALIDITY = "APPROVE_JOB_CARD_VALIDITY",
    APPROVE_JOB_CARD_MANAGEMENT = "APPROVE_JOB_CARD_MANAGEMENT",
    JOB_CARD_CHANGE_WAREHOUSE = "JOB_CARD_CHANGE_WAREHOUSE",
    JOB_CARD_CHARGE_PACKAGING = "JOB_CARD_CHARGE_PACKAGING",

    ALLOWED_SERVICE_PORTAL = "ALLOWED_SERVICE_PORTAL",
    CAN_READ_ACCOUNT_TOKEN_VALIDITY = "CAN_READ_ACCOUNT_TOKEN_VALIDITY",
    CAN_WRITE_ACCOUNT_TOKEN_VALIDITY = "CAN_WRITE_ACCOUNT_TOKEN_VALIDITY",

    SHOW_HIDDEN_DESCRIPTION_IN_DOWNLOAD = "SHOW_HIDDEN_DESCRIPTION_IN_DOWNLOAD",
    ALLOW_CREATE_TO_OFFER_CARD_FOR_DEALER = "ALLOW_CREATE_TO_OFFER_CARD_FOR_DEALER",
    SHOW_DEALER_CONTENT = "SHOW_DEALER_CONTENT",
    SHOW_END_CUSTOMER_CONTENT = "SHOW_END_CUSTOMER_CONTENT",
    SHOW_EMPLOYEE_CONTENT = "SHOW_EMPLOYEE_CONTENT",
}

export interface UserOperatorType {
    pin: string;
    token_ttl: number;
    machine_profile: number;
    machines: Array<number>;
    alias: string | undefined;
    machine_control: string;
}

export interface UserType {
    id: number;
    full_name: string;
    email: string;
    is_active: boolean;
    phone: string;
    roles: Array<number>;
    operator: UserOperatorType | undefined;
    customer: number | null;
    customer_embed?: CustomerType | null;
    readonly privileges?: string[];
}

export const DefaultUserOperatorType = Object.freeze<UserOperatorType>({
    pin: randomPin(),
    token_ttl: 30,
    machine_profile: 0,
    machines: [],
    alias: "",
    machine_control: "ISO",
});

export const DefaultUserType = Object.freeze<UserType>({
    id: 0,
    full_name: "",
    email: "",
    is_active: false,
    phone: "",
    roles: [],
    operator: undefined,
    customer: null,
});

export interface ImpersonationCodeResponse {
    code: string
    portalLink: string
}

export default {
    async getAllServerTable(stParams: ServerTableParamsType) {
        return await HTTP.get<PaginatedType<Array<UserType>>>("/user/", {
            params: {
                ...getServerTableQuery(stParams),
            },
        });
    },

    async getModelInfo() {
        return await HTTP.options<DRFOptionsResponse>("/user/");
    },

    async save(id: number, userType: Partial<UserType>) {
        if (!id) {
            return await HTTP.post<UserType>("/user/", userType);
        }
        return await HTTP.patch<UserType>(`/user/${id}/`, userType);
    },

    async delete(id: number) {
        return await HTTP.delete<UserType>(`/user/${id}/`);
    },

    async get(id: number) {
        return await HTTP.get<UserType>(`/user/${id}/`);
    },

    async getMachines(id: number) {
        return await HTTP.get<MachineType[]>(`/user/${id}/machines/`);

    },

    async getAvailableMachines(id: number) {
        return await HTTP.get<MachineType[]>(`/user/${id}/available-machines/`);

    },

    async getFiltered(stParams: ServerTableParamsType, active: boolean | null = null, hasPrivileges: string[] | null = null) {
        let extraFilters: Record<string, string> = {};

        // query filters
        if (active !== null) {
            stParams.filter["is_active"] = active ? "true" : "false";
        }

        if (hasPrivileges !== null) {
            extraFilters["hasPrivileges"] = hasPrivileges.join(",");
        }

        return await HTTP.get<PaginatedType<Array<UserType>>>("/user/", {
            params: {
                ...extraFilters,
                ...getServerTableQuery(stParams),
            }
        });
    },

    async getOperators(stParams: ServerTableParamsType) {
        return this.getFiltered(stParams, null, [UserPrivilegeEnum.OPERATOR]);
    },

    async getActiveOperators(stParams: ServerTableParamsType) {
        return this.getFiltered(stParams, true, [UserPrivilegeEnum.OPERATOR]);
    },

    async getActiveBDM(stParams: ServerTableParamsType) {
        return this.getFiltered(stParams, true, [UserPrivilegeEnum.ATLAS_BDM]);
    },

    async me() {
        return await HTTP.get<UserType>(`/me/`);
    },

    async invite(id: number) {
        return await HTTP.post(`/user/${id}/send/invite/`);
    },

    async resetPassword(id: number) {
        return await HTTP.post(`/user/${id}/send/password-reset/`);
    },

    async getPortalImpersonateLink(id: number) {
        return await HTTP.post<ImpersonationCodeResponse>(`/user/${id}/impersonate/`)
    }
};
