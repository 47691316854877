<script setup lang="ts">
import FGILogoSVG from '@/assets/icons/favicon.svg'
import { UserPrivilegeEnum } from '@/api/users'
import useLoginManager from '@/services/loginManager'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const loginManager = useLoginManager()

const iAmDeveloper = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.DEVELOPER))
const AllowCustomers = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_CUSTOMERS))
const AllowOperators = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_OPERATORS))
const AllowMachines = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_MACHINES))
const AllowUsers = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_USERS))
const AllowDoctor = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_DOCTOR))
const AllowSystem = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_SYSTEM))
const AllowCRM = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_CRM))
const AllowCRMPool = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_CRM_POOL))
const AllowCRMBilling = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_CRM_BILLING))
const AllowCRMLeads = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_CRM_LEADS))
const AllowCRMDeals = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_CRM_DEALS))
const AllowCRMSpam = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_CRM_SPAM))
const AllowExports = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.ATLAS_ALLOW_CRM_EXPORT))

function onAvatarClick() {
  const userId = loginManager.me.value?.id
  if (userId) {
    router.push({ name: 'user-card', params: { id: userId, tab: 'detail' } })
  } else {
    console.error('User ID is undefined')
  }
}
</script>

<template>
  <!-- USER -->
  <v-list>
    <v-list-item @click="onAvatarClick" :prepend-avatar="FGILogoSVG" :title="loginManager.me.value?.name" density="compact">
      <v-list-item-subtitle class="text-small">
        {{ loginManager.me.value?.email }}
      </v-list-item-subtitle>
    </v-list-item>
  </v-list>

  <v-divider></v-divider>

  <!-- MAIN MENU -->
  <v-list nav density="compact" :lines="false">
    <v-list-item prepend-icon="mdi-view-dashboard" title="Home" to="/"></v-list-item>
    <v-list-item v-if="AllowCustomers" prepend-icon="mdi-emoticon-cool-outline" title="Customers" to="/customers"></v-list-item>
    <v-list-item v-if="AllowOperators" prepend-icon="mdi-card-account-details-outline" title="Operators" to="/machine/operators"></v-list-item>
    <v-list-item v-if="AllowMachines" prepend-icon="mdi-forklift" title="Machines" to="/machines"></v-list-item>
    <v-list-item v-if="AllowUsers" prepend-icon="mdi-account-group-outline" title="Users" to="/users"></v-list-item>
    <v-list-item v-if="iAmDeveloper" prepend-icon="mdi-wrench-cog-outline" title="Servis" to="/servis"></v-list-item>

    <!-- DOCTOR -->
    <v-list-group v-if="AllowDoctor" value="Doctor">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="mdi-hospital-box-outline" title="Doctor"></v-list-item>
      </template>

      <v-list-item v-if="iAmDeveloper" prepend-icon="mdi-sigma" title="Summary" to="/doctor/summary"></v-list-item>
      <v-list-item v-if="AllowDoctor" prepend-icon="mdi-message-text-fast-outline" title="Messages" to="/doctor/messages"></v-list-item>
      <v-list-item v-if="AllowDoctor" prepend-icon="mdi-fingerprint" title="Disclaimers" to="/doctor/disclaimers"></v-list-item>
      <v-list-item v-if="AllowDoctor" prepend-icon="mdi-alert-circle" title="Errors" to="/doctor/errors"></v-list-item>
      <v-list-item v-if="AllowDoctor" prepend-icon="mdi-alert-circle" title="Errors v.2" to="/doctor/errors2"></v-list-item>
      <v-list-item v-if="AllowDoctor" class="pl-0" prepend-icon="mdi-reload-alert" title="Overloads" to="/doctor/overloads"></v-list-item>
    </v-list-group>

    <v-divider></v-divider>

    <!-- CRM -->
    <v-list-group v-if="AllowCRM" value="CRM">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="mdi-account-switch-outline" title="CRM"></v-list-item>
      </template>
      <v-list-item v-if="AllowCRMPool" prepend-icon="mdi-queue-first-in-last-out" title="Pool" to="/crm/pool"></v-list-item>
      <v-list-item v-if="AllowCRMBilling" prepend-icon="mdi-cash-100" title="Billing" to="/crm/billing"></v-list-item>
      <v-list-item v-if="AllowCRMLeads" prepend-icon="mdi-emoticon-cool-outline" title="Customers" to="/crm/customers"></v-list-item>
      <v-list-item v-if="AllowCRMLeads" prepend-icon="mdi-human-queue" title="Leads" to="/crm/lead"></v-list-item>
      <v-list-item v-if="AllowCRMDeals" prepend-icon="mdi-account-file-outline" title="Deals" to="/crm/deal"></v-list-item>
      <v-list-item v-if="AllowCRMSpam" prepend-icon="mdi-email-remove-outline" title="Spam" to="/crm/spam"></v-list-item>
      <v-list-item v-if="AllowSystem" prepend-icon="mdi-format-list-bulleted" title="All tickets" to="/crm/all"></v-list-item>
      <v-list-item v-if="AllowExports" prepend-icon="mdi-export" title="Exports" to="/crm/exports"></v-list-item>
    </v-list-group>

    <v-divider></v-divider>

    <!-- SYSTEM -->
    <v-list-group v-if="AllowSystem" value="System">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="mdi-cog-outline" title="System"></v-list-item>
      </template>

      <v-list-item v-if="AllowSystem" class="pl-0" prepend-icon="mdi-security" title="Roles" to="/roles"></v-list-item>
      <v-list-item v-if="AllowSystem" class="pl-0" prepend-icon="mdi-card-account-details-outline" title="Profiles" to="/profiles"></v-list-item>
      <v-list-item v-if="AllowSystem" class="pl-0" prepend-icon="mdi-car-battery" title="Battery types" to="/machine/battery"></v-list-item>
      <v-list-item v-if="AllowSystem" class="pl-0" prepend-icon="mdi-format-list-numbered" title="Machine types" to="/machine/types"></v-list-item>
      <v-list-item
        v-if="AllowSystem"
        class="pl-0"
        prepend-icon="mdi-alpha-t-circle-outline"
        title="Teltonika conf"
        to="/teltonika-configuration"
      ></v-list-item>
      <!-- <v-list-item v-if="AllowSystem" class="pl-0" prepend-icon="mdi-alpha-d-circle-outline" title="Danfoss conf"
                to="/danfoss-configuration"></v-list-item> -->
      <v-list-item v-if="AllowSystem" class="pl-0" prepend-icon="mdi-email-fast-outline" title="Mail log" to="/mail-log"></v-list-item>
      <v-list-item v-if="AllowSystem" class="pl-0" prepend-icon="mdi-account-wrench-outline" title="CRM settings" to="/crm/system"></v-list-item>
    </v-list-group>
  </v-list>
</template>
